<template>

  <!-- Start Content-->
  <div>

    <router-link :to="{ name: returnPage }" class="btn btn-primary waves-effect waves-light btn-back-fixed">
      <i class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}}
    </router-link>

    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="!jsonErr">
      <Form @submit="onSubmit" :validation-schema="schema">

        <div class="row" v-if="saveLoading">
          <div class="col-12">
            <div class="card-box d-flex justify-content-center">
              <div class="spinner-border m-12" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card-box">
              <h2 class="header-title mb-3"></h2>
               <div class="col-lg-12">
                      <div class="search-result-box">
                      
                  <div class="tab-content" style="width:100%;background:#fff">
                  <div style="width: 25%;float:left;"> 
                                    
                    <div class="lang" v-html="lan.CorpName" ></div>:<br>
                  <input class="form-control" id="address" style="width:200px;display:inline-block;flex: none !important;" type="text" v-model="CorpName" >
                
                <div style="width: 100%;height:10px"></div>
                <div class="lang" v-html="lan.CorpNum" ></div>:<br>
                  <input class="form-control" id="CorpNum" style="width:200px;display:inline-block;flex: none !important;" type="text" v-model="CorpNum" >
                
                <div style="width: 100%;height:10px"></div>
                  <div class="lang" v-html="lan.address" ></div>:<br>
                  <input class="form-control" id="address" style="width:200px;display:inline-block;flex: none !important;" type="text" placeholder="Search" aria-label="Search" v-model="address" >
                
                <div style="width: 100%;height:10px"></div>
                <div class="lang" v-html="lan.distance" ></div>: (m) <div class="help">
                  <ctooltip :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'" :align="'right'" :help_text="lan.help_cbreai_distance"/>
                  </div><input class="form-control mr-1" style="width:90px !important; margin-left: 23px;display:inline-block; flex: none !important;" type="text" placeholder="Distance" aria-label="Distance" v-model="distance" >
                  <div class="lang" v-html="lan.industry" ></div>:  <select class="form-control" v-model="industry">
                        <option value="">{{lan.all}}</option>              
                        <option v-for="(item,index) in industryData" :key="index" :value="item.name">{{item.name}}</option>
                      </select>
                             
                  <div class="lang" v-html="lan.sort" ></div>:  <select class="form-control" v-model="sort">
                        <option value="">{{lan.all}}</option>              
                        <option v-for="(item,index) in sortData" :key="index" :value="item.name">{{item.value}}</option>
                      </select>
                
                  <div style="width: 100%;height:10px"></div>
                  <span class="search-btn" >
                        <button type="button" class="btn waves-effect waves-light btn-blue" @click="search"><i class="fa fa-search mr-1"></i> <div class="lang" v-html="lan.search" ></div></button>
                    </span>
                  </div>
                      <div class="embed-responsive-16by9 tab-pane show active tc1" style="width: 75%;position:relative; float:left;" id="tc1" >
                      <div class="d-flex justify-content-center p-5 loading" v-if="isLoading">
                                            <div class="spinner-border m-12" role="status">
                                              <span class="sr-only">Loading...</span>
                                            </div>
                                          </div>
                          <div class=" mb-2" style="position:relative">
                          
                            <div id="googleMap" class="google"></div>
                            <div class="col-12">
                              <div class="responsive-table-plugin">

                                <div class="table-rep-plugin">
                                  <div class="table-responsive">
                                    <table class="table table-striped focus-on">
                                      <thead>
                                        <tr>
                                          <!-- <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="'table-row-' + tableHeader" >{{ tableHeader }}</th> -->
                                          <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="'table-row-' + tableHeader" >{{$language(tableHeader) }}</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody v-if="mapList.length>0">
                                        <tr v-for="(obj, idNum) in mapList" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)">
                                          
                                          <td >
                                          {{(obj['BuildingName']==undefined ?'':obj['BuildingName'])}}
                                          </td>
                                          <td >
                                          {{(obj['Grade']==undefined ?'-':obj['Grade'])}}
                                          </td>
                                          <td >
                                          {{(obj['Address']==undefined ?'':obj['Address'])}}
                                          </td>
                                          <td >
                                          {{(obj['market_price']==undefined ?'':obj['market_price'])}}
                                          </td>
                                          <td >
                                          {{(obj['distance']==undefined ?'':obj['distance'])}}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div class="p-2">
                                      <code v-if="mapList.length==0"><div class="lang" v-html="lan.no_data"></div></code>
                                    </div>
                                  </div> <!-- end .table-responsive -->
                                </div> <!-- end .table-rep-plugin-->
                                </div>
                            </div>
                          </div>
                         
                      </div>
                    </div>
                  </div>
                </div>  
            </div>
          </div>
        </div>

        
        <div class="row" v-if="saveLoading">
          <div class="col-12">
            <div class="card-box d-flex justify-content-center">
              <div class="spinner-border m-12" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12">
            <div class="card-box">
              <div class="alert alert-primary" v-if="saveAlert === 1">{{$language('レポートを保存しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 9">{{$language('レポートの保存に失敗しました')}}</div>
              <div class="alert alert-danger" v-else-if="saveAlert === 99">{{$language('入力内容に問題があります')}}</div>
              <div class="alert alert-danger" v-if="deleteAlert">{{ deleteAlert }}</div>
              <div class="alert alert-warning" v-if="downloadAlert">{{ downloadAlert }}</div>
              <div class="btn-toolbar justify-content-end mb-0" role="toolbar">
                <a href="#/report" class="btn btn-primary waves-effect waves-light btn-back-fixed"><i class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}} </a>
                <div class="d-flex align-items-center mr-0 mr-md-3 mb-1 mb-md-0">{{$language('公開状態')}} : 
                  <button type="button" class="btn btn-success ml-1" v-if="publishState" @click="PrivateReport">&nbsp;&nbsp;{{$language('公開')}}&nbsp;&nbsp;</button>
                  <button type="button" class="btn btn-secondary ml-1" v-if="!publishState" @click="PublishReport">{{$language('非公開')}}</button>
                </div>
                <button type="submit" class="btn btn-primary ml-1 mb-1 mb-md-0" v-if="isValid" @click="saveAlert = 99">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isValid">{{$language('save')}}</button>
                <button type="button" class="btn btn-primary ml-1 mb-1 mb-md-0" @click="DownloadReport" v-if="isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-primary disabled ml-1 mb-1 mb-md-0" v-if="!isEdit">{{$language('報告書を見る')}}</button>
                <button type="button" class="btn btn-danger ml-1 ml-md-5 mb-1 mb-md-0" @click="CancelDeleteReport" v-if="isEdit">{{$language('削除')}}</button>
                <button type="button" class="btn btn-danger disabled ml-1 ml-md-5 mb-1 mb-md-0" v-if="!isEdit">{{$language('削除')}}</button>
              </div>
            </div>
          </div>
        </div>

      </Form>
    </div>
    <div v-else>
      <code>
        {{$language('データ取得に失敗しました')}}
      </code>
    </div>
      
  </div> <!-- container -->
</template>

<script>

import axios from 'axios'
import router from '@/router'
import { inject } from 'vue';

const { currentRoute } = router;

export default {
  name: 'CorpCreateAi',
  components: {
  },
  props: {
    returnPage: String
  },
  data() {
   
    return {
     
      jsonObj: [Object],
      jsonErr: false,
      isLoading: true,
      cur_building:null,
      isValid: true,
      isEdit: false,
      saveAlert: 0, // 保存結果
      saveLoading: false,
      reportId: '',
      deleteModal: false,
      deleteAlert: '',
      downloadAlert: '',
      publishState: false,
      gridLoading: false,
      gridResult: "",
      industryData:[],
      sortData:[],
      gridList: [],
      gridCount: 0,
      gridModal: false,
    
      userName: document.cookie.replace(/(?:(?:^|.*;\s*)name\s*=\s*([^;]*).*$)|^.*$/, "$1"),
      name_search:'',
      location_search:'',
      series: [],
      BuildingID:'',//物件ID
    
      center: { lat: Number, lng: Number },
      address: '',
      distance:500,
      sort:'kz',
      industry:'',
      CorpNum:'',
      CorpName:'',
      type:currentRoute.value.query.type==undefined ? "company":currentRoute.value.query.type,
      mapList:[],
      reload: 0,
      cad:'',
      map:null,
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          MapTypeId: {},
          Marker:{},
          InfoWindow:{},
          Circle:{},
        },
      },
      markers:[],
      infowindow:[],
      curindex:"tc1",
      tableHeaders:[],
      // company
       viewParams: {
        name: 'CorpReference',
        apiUrl: '/v1/corps_reference/',
        articleView: 'CorpReferenceArticle',
        articleParamId: 'id',
        dataType:100,
      },
      offset: 0,
      listStrage: {
        query:'',
        limit: 100,
        paged: 1,
      },  
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      cur_lan:'jp',
      ccenter:'',
      radius:0,
      isPopup:false,
    }
  },
  mounted() {

    if( currentRoute.value.params.dataId ) {
      this.reportId = currentRoute.value.params.dataId
    } else {
      this.isLoading = false
    }
    this.sortData=[{'name':'kz','value':this.lan.firms_tend_to_enhance},{'name':'kz','value':this.lan.firms_tend_to_downsize}];
    var that=this;
    // edit
    
      this.isEdit = true,
      axios
        .get(process.env.VUE_APP_API2_URL_BASE+'/getCorpNeedInfo?id=' + this.reportId)
        .then(response => {
          if(this.CorpNum!=undefined){
          this.CorpNum=response.data.data.CorpNum;
          this.CorpName = response.data.data.CorpName
          this.address=response.data.data.address;
          this.distance=response.data.data.distance;
          this.industry=response.data.data.industry;
          this.sort=response.data.data.sort;}
          this.industryData=response.data.data.industryData;
          this.loadGoogleMapsScript().then(() => {
                this.google = window.google;
                this.autoaddress()
                this.initializeMap(that);
              }); 
          //公開ステータス確認
          if( response.data.publish === 1 ) {
            this.publishState = true
          } else {
            this.publishState = false
          }
         
        })
        .catch(error => {
            this.isLoading = false
            console.log(error),
            this.jsonErr = true
          }
        );
    

    
   
  },
  methods: {
    onSubmit() {
      this.saveLoading = true
      this.saveAlert = 0
     
      if(this.address==''){
        alert(this.$language('必須入力所在地。'))
      }
      if( this.reportId ) {
        axios
          .post(process.env.VUE_APP_API2_URL_BASE+'/CorpNeedSave/', {
            data:{
              id:this.reportId,
              name:localStorage.getItem('name'),
              CorpNum:this.CorpNum,
              CorpName:this.CorpName,
              address:this.address,
              distance:this.distance,
              industry:this.industry,
              sort:this.sort}
          })
          .then(() => {
              this.saveAlert = 1
              this.$nextTick(() => {
                this.saveLoading = false
              })
            }
          )
          .catch(error => {
              console.log(error)
              this.saveAlert = 9
              this.saveLoading = false
            }
          );
          this.$nextTick(() => {
            window.parent.postMessage(['loaded'], "*");
          })
      } else {
        axios
          .post(process.env.VUE_APP_API2_URL_BASE+'/CorpNeedSave/', {
            data:{
              name:localStorage.getItem('name'),
              CorpNum:this.CorpNum,
              CorpName:this.CorpName,
              address:this.address,
              distance:this.distance,
              industry:this.industry,
              sort:this.sort
            }
          })
          .then(response => {
              this.reportId = response.data.id
              this.$router.push('/corp_need/edit/' + this.reportId)
              this.saveAlert = 1
              this.saveLoading = false
            }
          )
          .catch(error => {
              console.log(error)
              this.saveAlert = 9
              this.saveLoading = false
            }
          );
        this.isEdit = true
        this.$nextTick(() => {
          window.parent.postMessage(['loaded'], "*");
        })
      }
    },
    
    SaveReport: function() {
      this.saveLoading = true
      this.saveAlert = 0
      if( this.reportId ) {
        axios
          .put(process.env.VUE_APP_API2_URL_BASE+'/CorpNeedSave', {
            data:{
              id:this.reportId,
              name:localStorage.getItem('name'),
              CorpNum:this.CorpNum,
              CorpName:this.CorpName,
              address:this.address,
              distance:this.distance,
              industry:this.industry,
              sort:this.sort
            }
          })
          .then(() => {
              this.saveAlert = 1
              this.$nextTick(() => {
                this.saveLoading = false
              })
            }
          )
          .catch(error => {
              console.log(error)
              this.saveAlert = 9
              this.saveLoading = false
            }
          );
          this.$nextTick(() => {
            window.parent.postMessage(['loaded'], "*");
          })
      } else {
        axios
          .post(process.env.VUE_APP_API2_URL_BASE+'/CorpNeedSave', {
            data:{
              name:localStorage.getItem('name'),
              CorpNum:this.CorpNum,
              CorpName:this.CorpName,
              address:this.address,
              distance:this.distance,
              industry:this.industry,
              sort:this.sort
            }
          })
          .then(response => {
              this.reportId = response.data.id
              this.$router.push('edit/' + this.reportId)
              this.saveAlert = 1
              this.saveLoading = false
            }
          )
          .catch(error => {
              console.log(error)
              this.saveAlert = 9
              this.saveLoading = false
            }
          );
        this.isEdit = true
        this.$nextTick(() => {
          window.parent.postMessage(['loaded'], "*");
        })
      }
    },
    PrivateReport: function() {
      this.summary.publish = 0
      this.publishState = false
    },
    PublishReport: function() {
      this.summary.publish = 1
      this.publishState = true
    },
    DownloadReport: function() {
      
      //location.href='/#/build_info?BuildingID='+this.BuildingID+'&tolink=report';
      /*let xmldata = ''

      if( this.reportId ) {
        axios
          .get('/v1/corp_need/' + this.reportId + '/download/', {
            responseType: 'blob'
          })
          .then(response => (
              xmldata = response.data,
              this.$nextTick(() => {
                let blob = new Blob([xmldata])
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'report.xlsx'
                link.click()
              })
            )
          )
          .catch(error => {
              this.downloadAlert = 'ダウンロードに失敗しました。'
              console.log(error)
            }
          );
      }*/
    },
    CancelDeleteReport: function() {
      this.deleteModal = !this.deleteModal
    },
    DeleteReport: function() {
      if( this.reportId ) {
        axios
          .delete('/v1/corp_need/' + this.reportId, {
            summary: this.summary,
            author: this.userName
          })
          .then(() => {
            sessionStorage.reportPaged = 1 // 一覧の1頁目に戻す
            this.$router.push({
              name: this.returnPage,
              query: { deleteFlg: 1 }
            })
          }
          )
          .catch(error => {
              this.deleteAlert = this.$language('レポートの削除に失敗しました。')
              this.deleteModal = false
              console.log(error)
            }
          );
      }
    },
     initdraw(map){
      var _that=this;
      var drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.CIRCLE,
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
            },
          
            circleOptions: {
            strokeColor: "#003f2d",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#003f2d",
              fillOpacity: 0.35,
              clickable: false,
              editable: true,
              zIndex: 1,
            },
          });

      drawingManager.setMap(map);
      window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
        if (event.type == 'circle') {
          _that.ccenter=JSON.parse(JSON.stringify(event.overlay.getCenter()));
          _that.radius=parseInt(event.overlay.getRadius());
          _that.distance=_that.radius;
          console.log(_that.radius+','+_that.distance+"<1>")
          new window.google.maps.Geocoder().geocode({ location :event.overlay.getCenter() }, function (results,status) {
            console.log(results);
            console.log(_that.ccenter);
            if (status === "OK" && results[0]) {
        
            _that.address=results[0].formatted_address;
            document.querySelector("#address").value=_that.address;
            _that.radius=parseInt(event.overlay.getRadius());
            _that.distance=_that.radius;
            console.log(_that.radius+','+_that.distance+"<1.5>")
              _that.search();
              window.google.maps.event.addListener(event.overlay, 'radius_changed', function() {
              _that.radius=parseInt(event.overlay.getRadius());
              _that.distance=_that.radius;
              console.log(_that.radius+','+_that.distance+"<2>")
              _that.search();
              })
            }
          });
        }
        
      });
    },
    autoaddress(){

      let address1Field;
          address1Field = document.querySelector("#address");
          new this.google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ["jp"] },
          fields: ["address_components", "geometry"],
          types: ["address"],
          });
          address1Field.focus();

    },
  
    search: function() {
      var that=this;
      this.address=document.querySelector("#address").value;
      console.log(this.ccenter)
      console.log(this.radius+','+this.distance+"<3>")
      axios.get(process.env.VUE_APP_API2_URL_BASE+'/getLocationByAddresRecommendationBuilding?address='+ this.address+'&center='+(this.ccenter=='' ? '':this.ccenter.lat+','+this.ccenter.lng)+'&distance='+ this.distance+'&CorpNum='+ this.CorpNum+'&CorpName='+ this.CorpName+'&industry='+ this.industry+'&sort='+ this.sort,{params:{id_token:localStorage.getItem('id_token')}})
        .then(response => {
          that.mapList=response.data.data;
          that.initializeMap(that);
        })
        .catch(error => (
            console.log(error),
            that.jsonErr = true,
            that.isLoading = false
          )
        );
    
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=places,drawing,geometry&v=weekly&channel=2";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 10000);
      });
    },
    initializeMap(_that) {
      const mapContainer = _that.$el.querySelector("#googleMap");
      
      const { Map, MapTypeId, Marker,InfoWindow,Circle} = _that.google.maps;
      const Geocoder = new _that.google.maps.Geocoder();
     
      this.cad='';
      // map create
      var zoom=7
      if(_that.distance>3000000){
        zoom=3
      }else if(_that.distance>2000000){
        zoom=4
      }else if(_that.distance>1000000){
        zoom=5
      }else if(_that.distance>500000){
        zoom=6
      }else if(_that.distance>200000){
        zoom=7
      }else if(_that.distance>100000){
        zoom=8
      }else if(_that.distance>50000){
        zoom=9
      }else if(_that.distance>20000){
        zoom=10
      }else if(_that.distance>10000){
        zoom=11
      }else if(_that.distance>5000){
        zoom=12
      }else if(_that.distance>2000){
        zoom=13
      }else if(_that.distance>1000){
        zoom=14
      }else if(_that.distance>500){
        zoom=15
      }else if(_that.distance>200){
        zoom=16
      }else if(_that.distance>100){
        zoom=17
      }else if(_that.distance>50){
        zoom=18
      }else if(_that.distance>20){
        zoom=19
      }else{
        zoom=20;
      }
      _that.map = new Map(mapContainer, {
        zoom: zoom,
        mapTypeId: MapTypeId.ROADMAP,
      });

    let addr='';
    if(_that.address==""){
      addr='東京';
    }else{
      addr=_that.address
    }
    console.log(addr);

      new Geocoder.geocode({ address: addr }, function (results, status) {
        if (status === "OK" && results[0]) {
          let locate = results[0].geometry.location;
          
          let center = {
            lat: parseFloat(locate.lat()),
            lng: parseFloat(locate.lng()),
          };
          if(_that.ccenter!=null && _that.ccenter.lat!=null){
            center.lat=_that.ccenter.lat;
            center.lng=_that.ccenter.lng;
            _that.ccenter=center;
          }
          
          
          // set locate
          _that.map.setCenter(center);
          var cc=new Circle({
            strokeColor: "#003f2d",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#003f2d",
            fillOpacity: 0.35,
            map:_that.map,
            center: center,
            editable:true,
            radius: parseInt(_that.distance),
          });
          _that.google.maps.event.addListener(cc, 'radius_changed', function() {
            _that.radius=parseInt(cc.getRadius());
            _that.distance=_that.radius;
            _that.search();
          
          });      
          //_that.initdraw(_that.map)
          //_that.mapList.push({latitude:locate.lat(),longitude:locate.lng(),address:_that.address})
          _that.markers=[];
          _that.infowindow=[];
          if(_that.mapList.length>0){
            _that.mapList.map((obj,i) => {
               _that.infowindow[i] = new InfoWindow({
                content: '<div id="content">' +
                            '<div id="siteNotice">' +
                            "</div>" +
                            '<h3 id="firstHeading" class="firstHeading">'+(obj.BuildingName==undefined ?'':obj.BuildingName)+'</h3>' +
                            '<div id="bodyContent">' +
                            "<p>竣工日 ："+(obj['CompletionDate']==undefined || obj['CompletionDate']=='NaT' ?'':obj['CompletionDate'].substr(0,10))+"<br>住所： "+obj.Address+"</p>" +
                            "</div>" +
                            "</div>"
              });
              _that.markers[i]=new Marker({
                position: {
                          lat: parseFloat(obj.MapCenterLat),
                          lng: parseFloat(obj.MapCenterLong)},
                map:_that.map,
                title: obj.BuildingName==undefined ?'':obj.BuildingName
              });
              _that.markers[i].addListener("click", () => {
              _that.infowindow[i].open({
                anchor: _that.markers[i],
                map:_that.map,
                shouldFocus: false
              });
            });

            });
            
           
          }  
          //pano.setPosition(_that.center);
        } else {
          console.log("failed load google api  : " + status);
          return;
        }
      });
    },
  
    gotowindow(i){
      var _that=this;
      if(this.cad!=''){
        _that.infowindow[this.cad.replace('k','')].close();
      }
      this.cad='k'+i;
      _that.infowindow[i].open({
        anchor: _that.markers[i],
        map:_that.map,
        shouldFocus: false
      });
    }
  }
}
</script>

<style lang="scss">
.disabled .select2-selection--single {
  background: #dfdfdf !important;
}
</style>

<style lang="scss" scoped>
.valid-feedback,
.invalid-feedback {
  display: block !important;
}

.btn-back-fixed {
  position: fixed;
  right: 0;
  top: 16px;
  z-index: 255;
}

.apexcharts-legend.position-bottom.apexcharts-align-center,
.apexcharts-legend.position-top.apexcharts-align-center {
  width: 30.5%;
 }

.modal {
  display: block;
}

.modal-bg {
  background: rgba(50, 58, 70, .5);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 0;
}

.grid-modal-dialog {
  max-width: 1000px;
  white-space: nowrap;
  & tbody tr {
    cursor: pointer;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    &:hover {
      color: #343a40;
      background-color: #dae6ec;
      border-color: #d1e0e8;
    }
  }
}

@media screen and (max-width: 768px) {

  .grid-modal-dialog {
    margin: {
      left: auto;
      right: auto;
      top: 2.5rem;
    }
    max-width: 80vw;
  }

}
.google {
  height: 550px;
}
table td{max-width: 200px;}
.types{margin-left: 10px;}
.help{position: relative;margin-left: 10px;    display: inline-block;}
.help-box{margin-top:10%;width: 60%;}
</style>
